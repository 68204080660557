<template>
  <div class="oldBrand-add">
    <GlobalInfoBar
      :title="titleDetail"
      content="管理门店店员在报价时，在选择旧机品牌时将展示品牌图片及名称，下架品牌后将在商户端不做展示"
    />
    <GlobalChunk
      :icon="DetailIMG"
      :title="titleDetail"
      :padding="[32, 0, 95, 55]"
    >
      <el-row>
        <el-col :span="10">
          <GlobalForm
            :init-data="initData"
            :form-rules="formRules"
            :form-item-list="formItemList"
            @handleConfirm="handleConfirm"
            confirmBtnName="提交"
          >
            <el-form-item class="el_from" label="所属分类" slot="operation" :required="true">
              <el-checkbox-group v-model="machineTypeIds" v-if="classList">
                <el-checkbox v-for="item in classList" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
              <span v-else class="tip">暂无分类数据，请先添加，立即前往  <el-link type="primary" href="/classification/add">分类管理</el-link> 添加</span>
            </el-form-item>
            <Provinces slot="provinces" />
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
  </div>
</template>

<script>
import Provinces from "@/components/plugins/provinces";
import _api from "@/utils/request";
export default {
  name: "oldBrand-add",
  components: { Provinces },
  data() {
    return {
      titleDetail: "",
      DetailIMG: "",
      ResidentFormData: [],
      classList: [],
      machineTypeIds:[],
      initData: {
        staffName: "",
        headImg: "",
        cheadImg: "",
        isIos: ""
      },

      formItemList: [
        {
          key: "staffName",
          type: "input",
          labelName: "品牌名称",
          placeholder: "输入品牌名称（10字内）",
        },
        {
          key: "headImg",
          type: "upload",
          lableWidth:"110px",
          labelName: "B端品牌图标",
          placeholder:
            "最多支持上传1张图片，大小不超过5M，格式为JPG或PNG，建议尺寸100*100px",
        },
        {
          key: "cheadImg",
          type: "upload",
          lableWidth:"110px",
          labelName: "C端品牌图标",
          placeholder:
            "最多支持上传1张图片，大小不超过5M，格式为JPG或PNG，建议尺寸100*100px",
        },
        {
          key: "isIos",
          type: "radio",
          lableWidth:"110px",
          labelName: "是否苹果系统",
          option: [
            { label: "是", value: "01" },
            { label: "否", value: "02" },
          ],
        },
        { slotName: "operation" },
      ],

      formRules: {
        staffName: [
          {
            required: true,
            trigger: "blur",
            message: "输入品牌名称（10字内）",
            max: 10,
          },
        ],
        headImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传B端品牌图标",
          },
        ],
        cheadImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传C端品牌图标",
          },
        ],
        isIos: [
          {
            required: true,
            trigger: "blur",
            message: "请选择是或者否",
          },
        ],
      },
    };
  },
  created() {
    this.getClassList()
  },
  mounted() {
    if (this.$route.query.type === "edit") {
      this.DetailIMG = "edit";
      this.titleDetail = "编辑品牌";
      _api.ResidentInfo({ id: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          this.ResidentFormData = res.data;
          if (this.$route.query.type === "edit") {
            this.initData.staffName = this.ResidentFormData.name;
            this.initData.headImg = this.ResidentFormData.logo;
            this.initData.cheadImg = this.ResidentFormData.customLogo;
            this.initData.isIos = this.ResidentFormData.isIos;
            this.machineTypeIds= this.ResidentFormData.machineTypeIds?this.ResidentFormData.machineTypeIds:[];
          }
        } else {
          this.$message.error("res.msg");
        }
      });
    } else {
      this.titleDetail = "新增品牌";
      this.DetailIMG = "add";
    }
  },

  methods: {
    handleConfirm(data, cd) {
      console.log(data);
      cd();
      const oldBrandAddOrUpdateDto = {
        name: data.staffName,
        logo: data.headImg,
        customLogo:data.cheadImg,
        id: this.ResidentFormData.id,
        isIos: data.isIos,
        machineTypeIds:this.machineTypeIds
      };
      console.log(oldBrandAddOrUpdateDto);
      if (this.$route.query.type === "edit") {
        _api.ResidentEdit(oldBrandAddOrUpdateDto).then((res) => {
          if (res.code === 1) {
            this.$router.push({ path: "/oldBrand/Resident" });
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        _api.ResidentAdd(oldBrandAddOrUpdateDto).then((res) => {
          if (res.code === 1) {
            this.$router.push({ path: "/oldBrand/Resident" });
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        });
      }
    },
    // 获取分类集合
    getClassList() {
      _api.selectList().then(res => {
        this.classList = res.data
      })
    },
    FromBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
  .el_from {
    margin-left: -120px;
    .tip{
      color: #666666;
    }
  }
}
</style>
